import React from "react"
import "../global/_all-tags.css"

import Home from "./@home"
import SEO from "../components/seo"

const IndexPage = () => (
  <>
    <SEO title="Corporate" />
    <Home />
  </>
)

export default IndexPage
